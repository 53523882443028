<template>
  <div class="fill-height">
    <navbar
      :sectionName="sectionName"
      :drawer="drawer"
      @setDrawer="setDrawer"
    ></navbar>
    <sidebar
      :navigationModules="navigationModules"
      @setDrawer="setDrawer"
      :drawer="drawer"
      @setSectionName="setSectionName"
    ></sidebar>
    <router-view class="pa-5 fill-height"></router-view>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Navbar from "@/components/common/navigation/Navbar";
import Sidebar from "@/components/common/navigation/Sidebar";
import { APP_SECTIONS } from "../../../config/constants";
export default {
  name: "layout",
  components: {
    navbar: Navbar,
    sidebar: Sidebar
  },
  data() {
    return {
      sectionName: "",
      drawer: false,
      wasOptionsSelected: false,
      navigationModules: [
        {
          name: "Dashboard",
          mdiIcon: "mdi-poll-box",
          routeName: "dashboard",
          app_section: APP_SECTIONS.DASHBOARD
        },
        {
          name: "Consultantes",
          mdiIcon: "mdi-account-group",
          routeName: "patients",
          app_section: APP_SECTIONS.PATIENTS
        },
        {
          name: "Formulario",
          mdiIcon: "mdi-file",
          routeName: "forms",
          app_section: APP_SECTIONS.FORMS
        },
        {
          name: "Website",
          mdiIcon: "mdi-web",
          routeName: "website",
          app_section: APP_SECTIONS.WEBSITE
        }
      ]
    };
  },
  methods: {
    ...mapMutations("ux", ["toggleBackgroundDark"]),
    setDrawer(newValue) {
      this.drawer = newValue;
    },
    setSectionName(newValue) {
      this.sectionName = newValue;
    }
  },
  mounted() {
    this.toggleBackgroundDark(false);
  }
};
</script>
