<template>
  <!-- Horizontal App Bar  -->
  <v-app-bar
    app
    class="app-bar z-index"
    color="secondary"
    justify="space-between"
    clipped-left
  >
    <v-row
      justify="space-between"
      align="center"
      class="app-bar-container px-3"
    >
      <v-col xs="6" sm="4" align="start" class="app-bar-container-item">
        <v-row>
          <v-app-bar-nav-icon
            @click="setDrawer()"
            color="white"
            :class="
              !drawer || !$vuetify.breakpoint.mdAndUp
                ? 'menuButtonActive'
                : 'menuButtonNotActive'
            "
          >
            <v-icon medium dark v-if="drawer && $vuetify.breakpoint.mdAndUp"
              >mdi-close</v-icon
            >
          </v-app-bar-nav-icon>
          <v-toolbar-title
            v-if="$vuetify.breakpoint.mdAndUp"
            class="app-bar-container-item mt-2 ml-2"
          >
            <b>Venusa Live</b>
          </v-toolbar-title>
        </v-row>
      </v-col>
      <v-col sm="4" align="center" class="app-bar-container-item">
        <v-toolbar-title>{{ sectionName }}</v-toolbar-title>
      </v-col>
      <v-col xs="6" sm="4" align="end" class="app-bar-container-item">
        <span v-if="$vuetify.breakpoint.mdAndUp"> {{ getUserMessage() }}</span>
        <v-icon
          medium
          dark
          class="app-bar-user"
          v-if="$vuetify.breakpoint.mdAndUp"
          >mdi-account-circle</v-icon
        >
        <v-tooltip bottom v-if="$vuetify.breakpoint.smAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-icon medium dark class="app-bar-user" v-bind="attrs" v-on="on"
              >mdi-account-circle</v-icon
            >
          </template>
          <span>{{ getUserMessage() }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import jwt from "@/common/jwt.service";

export default {
  name: "navbar",
  props: {
    sectionName: {
      type: String,
      required: true
    },
    drawer: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    setDrawer() {
      this.$emit("setDrawer", !this.drawer);
    },
    logOut() {
      jwt.destroyToken();
      this.$store.dispatch("reset");
      this.$router.push({ name: `login` });
    },
    getUserMessage() {
      const currentUser = this.$store.getters["users/getUser"];
      if (!currentUser || !currentUser.therapist) {
        return "¡Bienvenido!";
      }
      return `Hola, ${currentUser.therapist.firstName} ${currentUser.therapist.lastName}`;
    }
  },
  async mounted() {
    jwt.setAuthHeaderToken();
  }
};
</script>

<style scoped>
.app-bar {
  color: white;
}
.app-bar-container {
  max-height: 100% !important;
}
.app-bar-container-item {
  height: 100% !important;
  padding: 0px !important;
}
.app-bar-container-logo {
  max-height: 100%;
  width: 70%;
  margin-left: 2%;
}
.app-bar-logo {
  height: 50px;
  width: 90px;
}
.app-bar-user {
  margin-left: 2%;
}
.menuButtonActive {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
.menuButtonNotActive {
  transform: rotate(0deg);
  transition: transform 0.3s;
}
.z-index {
  z-index: 30;
}
</style>
